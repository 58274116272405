import React, { FunctionComponent, RefObject } from 'react';

interface ProgrammeNavigationItem {
  text: string;
  url: string;
  current: boolean;
}

interface ProgrammeNavigationProps {
  items: ProgrammeNavigationItem[];
  sticky?: boolean;
  element?: RefObject<HTMLElement>;
}

interface ProgrammeNavigationMenuItemProps {
  item: ProgrammeNavigationItem;
  baseStyles: string;
  activeClass: string;
  hoverClass: string;
  first: boolean;
  last: boolean;
}

const BASE_STYLES =
  'py-2 inline-block text-sm border-b-4 border-solid border-blue border-opacity-0';
const ACTIVE_CLASSES =
  'relative text-blue hover:text-blue focus:text-blue font-semibold border-opacity-100';
const HOVER_CLASSES =
  'relative navigation-item hover:text-blue focus:text-blue';

const BASE_STYLES_STICKY =
  'py-2 inline-block text-sm border-b-4 border-solid border-white border-opacity-0 text-white';
const ACTIVE_CLASSES_STICKY =
  'relative text-white hover:text-white focus:text-white font-semibold border-opacity-100';
const HOVER_CLASSES_STICKY =
  'relative navigation-item hover:text-white focus:text-white';

const ProgrammeNavigationMenuItem: FunctionComponent<
  ProgrammeNavigationMenuItemProps
> = ({ item, baseStyles, activeClass, hoverClass, first, last }) => (
  <li
    className={`flex-none pr-6 md:pr-12 ${first ? 'pl-8 md:pl-8' : ''} ${
      last ? 'pr-8 md:pr-8' : ''
    }`}
  >
    <a
      className={`${baseStyles} ${item.current ? activeClass : hoverClass}`}
      href={item.url}
    >
      {item.text}
    </a>
  </li>
);

const ProgrammeNavigation: FunctionComponent<ProgrammeNavigationProps> = ({
  items,
  sticky = false,
  element,
}) => {
  return (
    <nav
      ref={element}
      className={`programme-navigation overflow-x-scroll ${
        sticky ? '' : 'my-4 lg:max-w-6xl'
      } lg:mx-auto`}
    >
      <ul
        className={`list-none flex flex-row flex-nowrap items-center content-start ${
          sticky ? 'w-screen ' : ''
        } ${
          items.length <= 4
            ? 'md:gap-8 md:justify-center'
            : 'md:content-evenly md:justify-between'
        }`}
      >
        {items.map((item, i) => (
          <ProgrammeNavigationMenuItem
            key={i}
            item={item}
            first={i === 0}
            last={i === items.length - 1}
            baseStyles={sticky ? BASE_STYLES_STICKY : BASE_STYLES}
            activeClass={sticky ? ACTIVE_CLASSES_STICKY : ACTIVE_CLASSES}
            hoverClass={sticky ? HOVER_CLASSES_STICKY : HOVER_CLASSES}
          />
        ))}
      </ul>
    </nav>
  );
};

export { ProgrammeNavigation };
