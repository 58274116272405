import React, { FunctionComponent } from 'react';

interface TermsAndConditionsProps {
  title?: string;
  subtitle?: string;
}

const TermsAndConditionsHeader: FunctionComponent<TermsAndConditionsProps> = ({
  title = 'Terms and Conditions',
  subtitle,
}) => (
  <div className="px-6 py-16 max-w-screen-lg mx-auto bg-blue rounded-3xl shadow-md flex flex-col items-center space-x-4">
    <h1 className="flex-none text-4xl font-worksans font-light text-white mb-3">
      {title}
      <br /> {subtitle}
    </h1>
  </div>
);

export { TermsAndConditionsHeader };
