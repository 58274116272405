import React, { FunctionComponent } from 'react';
import { ProgrammeNavigation } from './Navigation';

interface ProgrammeNavigationItem {
  text: string;
  url: string;
  current: boolean;
}

interface ProgrammeNavigationProps {
  items: ProgrammeNavigationItem[];
  visible: boolean;
}

const StickyProgrammeNavigation: FunctionComponent<
  ProgrammeNavigationProps
> = ({ items, visible }) => (
  <div className={`sticky-nav overflow-hidden ${visible ? '' : 'hidden'}`}>
    <div className="bg-blue pt-4 pb-4">
      <ProgrammeNavigation items={items} sticky={true} />
    </div>
  </div>
);

export { StickyProgrammeNavigation };
