import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { Content, Layout } from './components/LayoutV2';
import { TermsAndConditionsHeader } from '@bunac/components/Programme/TermsConditions/Header';
import { ProgrammeNavigation } from './components/Programme/Navigation';
import useSticky from './hooks/useSticky';
import { StickyProgrammeNavigation } from './components/Programme/StickyNavigation';
import { MarkdownRender } from './components/Programme/MarkdownRender';

export const query = graphql`
  query TermsConditionsV2Query($id: Int!) {
    strapiProgrammes(strapiId: { eq: $id }) {
      strapiId
      Title
      Theme
      Terms
    }
  }
`;

interface Props {
  data: any;
  pageContext: any;
  location: any;
}

const TermsAndConditions: FunctionComponent<Props> = ({
  data,
  pageContext,
  location,
}) => {
  const programme = data.strapiProgrammes;
  const { Terms: termsAndConditions } = programme;
  const navigation = pageContext.navigation;

  const menu = navigation.map((nav: any) => ({
    text: nav.title,
    url: nav.to,
    current: location.pathname.startsWith(nav.to),
  }));

  const { isSticky, element } = useSticky();

  return (
    <Layout title={`${programme.Title} | Terms and Conditions`}>
      <Content>
        <StickyProgrammeNavigation items={menu} visible={isSticky} />
        <div className="relative pt-4 min-h-full text-center">
          <TermsAndConditionsHeader
            title={`Terms & Conditions for`}
            subtitle={programme.Title}
          />
        </div>
        <ProgrammeNavigation items={menu} element={element} />
        <br />
        <div className="py-12 max-w-4xl m-auto">
          <MarkdownRender content={termsAndConditions} />
        </div>
      </Content>
    </Layout>
  );
};

export default TermsAndConditions;
