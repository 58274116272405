import { useEffect, useState, useRef, useCallback } from 'react';
import { debounce } from '@bunac/lib/debounce';

function useSticky() {
  const [isSticky, setSticky] = useState(false);
  const element = useRef<HTMLElement>(null);

  const handleScroll = useCallback(() => {
    if (element.current === null) {
      return;
    }
    window.scrollY > element.current.getBoundingClientRect().bottom + 450
      ? setSticky(true)
      : setSticky(false);
  }, [element]);

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll));
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [handleScroll]);

  return { isSticky, element };
}

export default useSticky;
